var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row my-4"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("Müşteri Bilgileri")]),_c('div',{staticClass:"d-flex mt-4"},[_c('h6',{staticClass:"text-xl"},[_vm._v("Müşteri Puanı:")]),_c('div',{staticClass:"d-flex ml-4"},[_c('h6',[_vm._v("100")]),_c('b-badge',{staticClass:"ml-2 mb-5",attrs:{"variant":"success"}},[_vm._v("Güvenilir")])],1)])])]),_c('div',{staticClass:"row mt-4"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"col-12 d-flex justify-content-end mt-4"},[_c('b-button',{attrs:{"variant":"success"}},[_vm._v("Kaydet")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("Profile Bilgileri")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Ad")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"name","name":"name"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"surname"}},[_vm._v("Soyad")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"surname","id":"surname"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("E-Posta")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","name":"email","id":"email"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tckn"}},[_vm._v("TCKN")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"tckn","id":"TCKN"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Şifre")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","name":"password","id":"password"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"confirmPassword"}},[_vm._v("Şifre Tekrar")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","name":"confirmPassword","id":"confirmPassword"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"birthdate"}},[_vm._v("Doğum Tarihi")]),_c('input',{staticClass:"form-control",attrs:{"type":"date","name":"birthdate","id":"birthdate"}})])])
}]

export { render, staticRenderFns }